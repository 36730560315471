const mathcPath = (history, path) => {
  if (history.pathname.includes(path)) {
    return true;
  }

  return false;
};

const genrateDownloadLink = (filename) => {
  const extension = filename.trim().split(".").pop().toUpperCase();

  let url = "";
  switch (extension) {
    case "PDF":
      url = "data:application/pdf;base64,";
      break;
    case "XLSX":
      url =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
      break;
    case "XLS":
      url = "data:application/vnd.ms-excel;base64,";
      break;
    case "DOC":
      url = "data:application/msword;base64,";
      break;
    case "DOCX":
      url =
        "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,";
      break;
    default:
      url = "";
      break;
  }
  return url;
};

const calculateAmmount = (ammount) => {
  if (ammount) {
    const amt = Number(parseFloat(ammount).toFixed(2)).toLocaleString("en", {
      minimumFractionDigits: 2,
    });
    return amt;
  }

  return "N/A";
};

export { mathcPath, genrateDownloadLink, calculateAmmount };
